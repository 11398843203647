/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import download from "downloadjs";
import * as React from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import axios, { AxiosError } from "axios";
import { useEffect } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import LoadingBackdrop from "components/LoadingBackdrop";

// Data
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MultipleSelectCheckmarks from "./components/MultipleSelectCheckmarks";
import SingleSelect from "./components/SingleSelect";
import MDButton from "components/MDButton";
import { useAuthFetch } from "hooks";
import dayjs from "dayjs";
import JSZip from "jszip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Tables() {
  const [personName, setPersonName] = React.useState([]);
  const [nombresEntidades, setNombresEntidades] = React.useState(["Entitats..."]);
  const [idsEntidades, setIdsEntidades] = React.useState([0]);
  const [eventos, setEventos] = React.useState([]);
  const { send: sendEntitiats, response: responseEntitiats, error: errorEntitats } = useAuthFetch();
  const { send: sendReports, response: responseReports, error: errorReports } = useAuthFetch();
  const [isLoading, setIsLoading] = React.useState(false);

  //DownloadPDF logic
  const createPDF = (entityId, fileName, cb) => {
    axios
      .post(
        process.env.REACT_APP_BASE_ADDRESS + "/reports",
        {
          type: "SGAE_AUTOLIQUIDATION",
          entity: {
            id: entityId,
          },
          start_date: initialDate.format("YYYY-MM-DD"),
          end_date: finalDate.format("YYYY-MM-DD"),
        },
        {
          timeout: 60000,
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        //console.log(zip);
        getPDF(response.data.id, fileName, cb);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPDF = (reportId, fileName, cb) => {
    axios
      .get(process.env.REACT_APP_BASE_ADDRESS + "/reports/" + reportId, {
        responseType: "blob",
        withCredentials: true,
        timeout: 60000,
      })
      .then((response) => {
        //const content = response.headers["content-type"];
        //download(response.data, fileName, content);
        cb(response.data);
        //zip.file(fileName, response.data, { binary: true });
        //setCounter(counter + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getExcel = (reportId, fileName) => {
    axios
      .get(process.env.REACT_APP_BASE_ADDRESS + "/reports/" + reportId, {
        responseType: "blob",
        withCredentials: true,
        timeout: 60000,
      })
      .then((response) => {
        const content = response.headers["content-type"];
        download(response.data, fileName, content);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createExcel = (fileName) => {
    console.log(fileName);
    axios
      .post(
        process.env.REACT_APP_BASE_ADDRESS + "/reports",
        {
          type: "EXCEL_AUTOLIQUIDATION",
          start_date: initialDateE.format("YYYY-MM-DD"),
          end_date: finalDateE.format("YYYY-MM-DD"),
          quarter: quarterE,
        },
        {
          timeout: 60000,
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        getExcel(response.data.id, fileName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Filter  logic
  const [initialDate, setInitialDate] = React.useState(dayjs().set("date", 1));
  const [finalDate, setFinalDate] = React.useState(dayjs().endOf("month"));
  const [entitatsFiltre, setEntitatsFiltre] = React.useState();
  const [quarter, setQuarter] = React.useState(0);
  //Filter  logic Excel
  const [initialDateE, setInitialDateE] = React.useState(dayjs().set("date", 1));
  const [finalDateE, setFinalDateE] = React.useState(dayjs().endOf("month"));
  const [quarterE, setQuarterE] = React.useState(0);

  const handleGenerate = async () => {
    let zip = new JSZip();
    setIsLoading(true);
    if (entitatsFiltre.length == 1) {
      const entitat = entitatsFiltre[0];
      const fileName = "Autoliquidació de " + entitat + ".pdf";
      createPDF(idsEntidades[entitat], fileName, (data) => {
        download(data, fileName, "application/pdf");
        setIsLoading(false);
      });
    } else {
      for (let i = 0; i < entitatsFiltre.length; i++) {
        const entitat = entitatsFiltre[i];
        const fileName = "Autoliquidació de " + entitat + ".pdf";
        const data = await new Promise((resolve) => {
          createPDF(idsEntidades[entitat], fileName, (data) => {
            resolve(data);
          });
        });
        console.log("Count: " + i + " total: " + entitatsFiltre.length);
        zip.file(fileName, data, { binary: true });
      }
      zip.generateAsync({ type: "blob" }).then(function (content) {
        download(content, "autoliquidacions.zip", "application/zip");
      });
      setIsLoading(false);
    }
  };

  const handleGenerateExcel = () => {
    setIsLoading(true);
    createExcel(
      "Autoliquidació de " +
        initialDateE.format("DD/MM/YYYY") +
        " a " +
        finalDate.format("DD/MM/YYYY")
    );
  };

  function setDatesFromQuarter(val) {
    const idate = new Date("01/01/2000");
    idate.setDate(1);
    idate.setMonth(val * 3);
    idate.setFullYear(new Date().getFullYear());
    setInitialDate(dayjs(idate));

    const edate = new Date("01/01/2000");
    edate.setDate(30 + (val == 0 || val == 3 ? 1 : 0));
    edate.setMonth(2 + val * 3);
    edate.setFullYear(new Date().getFullYear());
    setFinalDate(dayjs(edate));
  }

  function setDatesFromQuarterE(val) {
    const idate = new Date("01/01/2000");
    idate.setDate(1);
    idate.setMonth(val * 3);
    idate.setFullYear(new Date().getFullYear());
    setInitialDateE(dayjs(idate));

    const edate = new Date("01/01/2000");
    edate.setDate(30 + (val == 0 || val == 3 ? 1 : 0));
    edate.setMonth(2 + val * 3);
    edate.setFullYear(new Date().getFullYear());
    setFinalDateE(dayjs(edate));
  }

  React.useEffect(() => {
    setDatesFromQuarterE(quarterE);
    sendEntitiats("get", "/entities", {}, {});
    setDatesFromQuarter(quarter);
  }, []);

  React.useEffect(() => {
    if (!errorEntitats && responseEntitiats) {
      console.log("ENTITATS: ");
      console.log(responseEntitiats);
      let ids = [];
      responseEntitiats.forEach((element) => {
        ids[element.name] = element.id;
      });
      setIdsEntidades(ids);
      setNombresEntidades(responseEntitiats.map((entidad) => entidad.name));
    }
  }, [responseEntitiats]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <LoadingBackdrop open={isLoading} handleOnClick={() => {}} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Documents autoliquidació
                </MDTypography>
              </MDBox>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "1rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  gap: "1rem",
                  justifyItems: "stretch",
                  marginTop: 15,
                }}
              >
                <div>
                  <SingleSelect
                    sx={{ width: 200 }}
                    name="Trimestre"
                    values={["1r Trimestre", "2n Trimestre", "3r Trimestre", "4t Trimestre"]}
                    defValue={quarterE}
                    onChange={(val) => {
                      setQuarter(val);
                      setDatesFromQuarter(val);
                    }}
                  />
                </div>
                <div style={{ display: "flex", gap: "inherit" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DatePicker
                      value={initialDate}
                      onChange={(newDate) => setInitialDate(newDate)}
                      // sx={{ minWidth: "250px" }}
                      label={"Des de"}
                      views={["year", "month", "day"]}
                    />
                    <DatePicker
                      value={finalDate}
                      onChange={(newDate) => setFinalDate(newDate)}
                      // sx={{ minWidth: "250px" }}
                      label={"Fins a"}
                      views={["year", "month", "day"]}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <MultipleSelectCheckmarks
                    values={nombresEntidades}
                    onChange={(e) => setEntitatsFiltre(e)}
                  />
                </div>
                <div style={{ marginLeft: "auto", marginRight: 5 }}>
                  <MDButton variant="outlined" color="info" onClick={handleGenerate}>
                    Generar document
                  </MDButton>
                </div>
              </div>
              <MDBox>
                <br></br>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={6} style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Resum Excel
                </MDTypography>
              </MDBox>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "1rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  gap: "1rem",
                  justifyItems: "stretch",
                  marginTop: 15,
                }}
              >
                <div>
                  <SingleSelect
                    sx={{ width: 200 }}
                    name="Trimestre"
                    values={["1r Trimestre", "2n Trimestre", "3r Trimestre", "4t Trimestre"]}
                    defValue={quarterE}
                    onChange={(val) => {
                      setQuarterE(val);
                      setDatesFromQuarterE(val);
                    }}
                  />
                </div>
                <div style={{ display: "flex", gap: "inherit" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DatePicker
                      value={initialDateE}
                      onChange={(newDate) => setInitialDateE(newDate)}
                      // sx={{ minWidth: "250px" }}
                      label={"Des de"}
                      views={["year", "month", "day"]}
                    />
                    <DatePicker
                      value={finalDateE}
                      onChange={(newDate) => setFinalDateE(newDate)}
                      // sx={{ minWidth: "250px" }}
                      label={"Fins a"}
                      views={["year", "month", "day"]}
                    />
                  </LocalizationProvider>
                </div>
                <div style={{ marginLeft: "auto", marginRight: 5 }}>
                  <MDButton variant="outlined" color="info" onClick={handleGenerateExcel}>
                    Generar Excel
                  </MDButton>
                </div>
              </div>
              <MDBox>
                <br></br>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
